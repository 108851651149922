import { FormLabel } from '@shared/components/ui/form';
import { Icons } from '../content/icons';
import { SimpleTooltip } from '../simple-tooltip';
import { FormProps } from './types';

export interface InputLabelProps {
  name: string;
  label?: FormProps['label'];
  optional?: boolean;
  tooltip?: JSX.Element;
}

export default function InputLabel({
  name,
  label,
  optional = false,
  tooltip,
}: InputLabelProps) {
  return (
    <FormLabel
      htmlFor={name}
      className="flex w-full flex-row items-center space-x-1"
    >
      <div>{label}</div>
      {optional && (
        <span className="text-xs font-normal text-muted-foreground">
          (optional)
        </span>
      )}
      {tooltip && (
        <div className="flex flex-1 justify-end pr-1">
          <SimpleTooltip
            trigger={<Icons.info size={16} className="" />}
            content={tooltip}
            delayDuration={100}
          />
        </div>
      )}
    </FormLabel>
  );
}
