import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React from 'react';
import Text from './text';

export type UnitVariant =
  | 'kg'
  | 'kWh'
  | 'gCO2e'
  | 'tCO2e'
  | 'kgCO2e/kg'
  | 'kgCO2e/kWh'
  | 'kgCO2e/kgfood';

interface UnitProps {
  variant: UnitVariant;
  className?: ClassValue;
}

const renderUnit = (variant: UnitVariant) => {
  switch (variant) {
    case 'gCO2e':
      return (
        <>
          gCO<sub>2</sub>e
        </>
      );
    case 'kgCO2e/kgfood':
      return (
        <>
          kgCO<sub>2</sub>e/kg<sub>food</sub>
        </>
      );
    case 'kgCO2e/kWh':
      return (
        <>
          kgCO<sub>2</sub>e/kWh
        </>
      );
    case 'kgCO2e/kg':
      return (
        <>
          kgCO<sub>2</sub>e/kg
        </>
      );
    case 'tCO2e':
      return (
        <>
          tCO<sub>2</sub>e
        </>
      );
    default:
      return variant;
  }
};

const Unit: React.FC<UnitProps> = ({ variant, className }) => {
  return (
    <Text variant="unit" className={cn('text-xs leading-[12px]', className)}>
      {renderUnit(variant)}
    </Text>
  );
};

export default Unit;
