import { Slot } from '@radix-ui/react-slot';
import { Icons } from '@shared/components/content/icons';
import { cn } from '@shared/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus:outline-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90 focus:outline-ring',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'border border-primary text-primary bg-background shadow-sm hover:bg-accent',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        ['outline-destructive'] : 'border border-destructive text-destructive bg-background shadow-sm hover:bg-error-50/35 ',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const iconSize = {
  default: 'h-4 w-4',
  sm: 'h-3.5 w-3.5',
  lg: 'h-4 w-4',
  icon: 'h-4 w-4',
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: React.ReactNode; // Icon component
  loading?: boolean; // Loading state
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading,
      icon,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const iconClassName = cn(
      iconSize[size || 'default'],
      size === 'icon' ? '' : 'mr-2'
    );
    icon = icon
      ? React.cloneElement(icon as React.ReactElement, {
          className: iconClassName,
        })
      : null;
    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
        disabled={loading || props.disabled}
      >
        <>
          {loading && (
            <Icons.loader2 className={cn(iconClassName, 'animate-spin')} />
          )}
          {(icon && !loading) && icon}
          {children}
        </>
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
