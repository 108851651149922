import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { Input, InputProps } from '@shared/components/ui/input';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { useFormContext } from 'react-hook-form';
import Unit, { UnitVariant } from '../content/unit';
import { FormProps } from './types';

type InputFormFieldProps = InputProps &
  FormProps & {
    inputClassName?: ClassValue;
    adornmentRight?: string;
    unit?: UnitVariant;
    optional?: boolean;
  };
export default function InputFormField({
  name,
  className,
  label,
  includeErrorMessage = false,
  inputClassName,
  adornmentRight,
  optional = false,
  unit,
  ...props
}: InputFormFieldProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('grid w-full items-center', className)}>
          {label && (
            <FormLabel htmlFor={name}>
              {label}{' '}
              {optional && (
                <span className="text-xs font-normal text-muted-foreground">
                  (optional)
                </span>
              )}
            </FormLabel>
          )}
          <div className="flex flex-row items-center space-x-2">
            <div className="relative flex-1">
              <FormControl>
                <Input
                  className={cn(
                    'w-full placeholder:text-gray-400',
                    {
                      'border-destructive': fieldState.error,
                      'pr-9': !!adornmentRight,
                    },
                    inputClassName
                  )}
                  {...field}
                  {...props}
                />
              </FormControl>
              {adornmentRight && (
                <p className="absolute inset-y-0 right-3 flex w-4 items-center text-sm text-muted-foreground">
                  {adornmentRight}
                </p>
              )}
            </div>
            {unit && <Unit variant={unit} className="text-xs" />}
          </div>
          {includeErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
