import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface TextCellProps {
  text: string;
  className?: ClassValue;
}

export function TextCell({ text, className }: TextCellProps) {
  return <p className={cn('', className)}>{text}</p>;
}
