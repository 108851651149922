import {
  AlertTriangle,
  Archive,
  ArrowUpCircle,
  BarChart,
  Building,
  Carrot,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleUser,
  ClipboardList,
  Clock,
  Cloud,
  CloudOff,
  Download,
  Edit2Icon,
  Factory,
  FileStack,
  Flame,
  HelpCircle,
  Home,
  Hourglass,
  Info,
  LayoutDashboard,
  LifeBuoy,
  LineChart,
  Link2,
  Loader,
  Loader2,
  Lock,
  LogOut,
  Menu,
  Moon,
  MoreHorizontal,
  Package,
  Paperclip,
  Pencil,
  PlusCircle,
  PlusIcon,
  Puzzle,
  Recycle,
  Refrigerator,
  Salad,
  Scale,
  ScanSearch,
  ScrollText,
  Send,
  Snowflake,
  Sprout,
  Sun,
  Tags,
  Thermometer,
  Truck,
  User,
  Wand2,
  X,
  Zap,
} from 'lucide-react';

export const Icons = {
  archive: Archive,
  arrowUpCircle: ArrowUpCircle,
  barChart: BarChart,
  carrot: Carrot,
  check: Check,
  checkCircle: CheckCircle,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  circleUser: CircleUser,
  clipboardList: ClipboardList,
  clock: Clock,
  cloud: Cloud,
  company: Building,
  dashboard: LayoutDashboard,
  download: Download,
  edit: Edit2Icon,
  ellipsis: MoreHorizontal,
  expired: CloudOff,
  factory: Factory,
  fileStack: FileStack,
  flame: Flame,
  help: HelpCircle,
  home: Home,
  hourglass: Hourglass,
  info: Info,
  lifeBuoy: LifeBuoy,
  lineChart: LineChart,
  link: Link2,
  loader: Loader,
  loader2: Loader2,
  lock: Lock,
  logOut: LogOut,
  menu: Menu,
  moon: Moon,
  package: Package,
  paperClip: Paperclip,
  pencil: Pencil,
  plus: PlusIcon,
  plusCircle: PlusCircle,
  puzzle: Puzzle,
  recycle: Recycle,
  refrigerator: Refrigerator,
  salad: Salad,
  scale: Scale,
  scanSearch: ScanSearch,
  scrollText: ScrollText,
  send: Send,
  snowflake: Snowflake,
  sprout: Sprout,
  sun: Sun,
  tags: Tags,
  thermometer: Thermometer,
  truck: Truck,
  user: User,
  wand2: Wand2,
  warning: AlertTriangle,
  x: X,
  zap: Zap,
};
